@import '../../../style/colors.scss';
// Fonts
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont,
  'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 500;
$font-size-base: 0.825rem;
$font-size-lg: 0.95rem;
$font-size-sm: 0.75rem;
$headings-font-weight: 500;
$small-font-size: 80%;

// Spacer
$spacer: 1rem;

$spacers: (
  0: 0,
  1: (
    $spacer * 0.25
  ),
  2: (
    $spacer * 0.5
  ),
  3: $spacer,
  4: (
    $spacer * 1.5
  ),
  5: (
    $spacer * 3
  ),
  6: (
    $spacer * 4.5
  ),
  7: (
    $spacer * 6
  )
);

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px
);

$grid-gutter-width: 24px;

// Grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
);

$container-padding-x: 0.75rem;

// Options
$enable-validation-icons: false;
$enable-rfs: false;
$enable-negative-margins: true;
$min-contrast-ratio: 3;

// Transitions
$transition-base: all 0.35s ease-in-out;

// Theme colors
$blue: #3f80ea;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #d9534f;
$orange: #fd7e14;
$yellow: #e5a54b;
$green: #4bbf73;
$teal: #20c997;
$cyan: #1f9bcf;

$white: #fff;
// $gray-100: #f4f7f9;
$gray-100: #f8f8f8;
$gray-200: #e2e8ee;
$gray-300: #dee6ed;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #020202;
$gray-900: #212529;
$black: #000;

$primary: $gray-700;
$secondary: $gray-700;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $color-cancelar;
$light: lighten($gray-200, 4%);
$dark: $color-primario-verde; //.btn-dark

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark
);

// Available as CSS variables (located in our _root.scss file)
$custom-colors: (
  primary-dark: darken($primary, 15%),
  primary-light: lighten($primary, 15%)
);

// Social colors
$social-colors: (
  'facebook': #3b5998,
  'twitter': #1da1f2,
  'google': #dc4e41,
  'youtube': #f00,
  'vimeo': #1ab7ea,
  'dribbble': #ea4c89,
  'github': #181717,
  'instagram': #e4405f,
  'pinterest': #bd081c,
  'flickr': #0063dc,
  'bitbucket': #0052cc
);

// Not modified by dark theme
$static-white: #fff;
$static-gray-100: #f8f9fa;
$static-gray-200: #e9ecef;
$static-gray-300: #dee2e6;
$static-gray-400: #ced4da;
$static-gray-500: #333333;
// $static-gray-500: #adb5bd;
$static-gray-600: #6c757d;
$static-gray-700: #495057;
$static-gray-800: #020202;
$static-gray-900: #212529;
$static-black: #000;
$static-dark: #293042;

// Body
$body-bg: #ffffff;
$body-color: $gray-600;

// Links
$link-decoration: none;
$link-hover-decoration: none;

// Common
$border-width: 1px;

// Fonts
$line-height-base: 1.625;
$line-height-lg: 1.625;
$line-height-sm: 1.625;

// Headings
$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;
$headings-color: $gray-700;

// Text
$text-muted: lighten($gray-600, 3);
$text-bold-color: $gray-700;

// Progress
$progress-height: 1rem;
$progress-height-sm: 0.5rem;
$progress-height-lg: 1.5rem;

// Shadows
$box-shadow-sm: 0 0.05rem 0.2rem rgba(0, 0, 0, 0.05);
$box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
$box-shadow-lg: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.05);

// Border radius
$border-radius-sm: 0.1rem;
$border-radius: 0.2rem;
$border-radius-lg: 0.3rem;

// Navbar
$navbar-padding-y: $spacer * 0.875;
$navbar-padding-x: $spacer * 1.25;
$navbar-nav-link-padding-x: 0.5rem;
$navbar-border-bottom: 0;
$navbar-box-shadow: 0 0 2rem 0 rgba($dark, 0.1);
$navbar-bg: $white;

// Navbar brand
$navbar-brand-padding-y: $spacer * 0.875;
$navbar-brand-padding-x: 0;
$navbar-brand-color: $gray-100;
$navbar-brand-font-weight: $font-weight-bold;
$navbar-brand-font-size: 1.15rem;
$navbar-brand-icon-color: $primary;

// Navbar input
$navbar-input-bg: darken($body-bg, 1%);

// Alerts
$alert-padding-y: 0.95rem;
$alert-padding-x: 0.95rem;
$alert-border-width: 0;

// Forms
$input-bg: $white;
$input-disabled-bg: $gray-200;
$input-color: $gray-700;
$input-btn-border-width: $border-width;
$input-btn-line-height: $line-height-base;
$input-btn-line-height-sm: $line-height-sm;
$input-btn-line-height-lg: $line-height-lg;
$input-btn-focus-width: 0.2rem;
$input-btn-padding-y: 0.25rem;
$input-btn-padding-y-sm: 0.15rem;
$input-btn-padding-y-lg: 0.35rem;
$input-btn-padding-x: 0.7rem;
$input-btn-font-size-lg: $font-size-lg;
$input-border-width: $input-btn-border-width;
$input-height-border: $input-border-width * 2;
$input-height-inner: ($font-size-base * $input-btn-line-height) +
  ($input-btn-padding-y * 2);
$input-height: calc(#{$input-height-inner} + #{$input-height-border});
$input-height-inner-sm: ($font-size-sm * $input-btn-line-height-sm) +
  ($input-btn-padding-y-sm * 2);
$input-height-sm: calc(#{$input-height-inner-sm} + #{$input-height-border});
$input-height-inner-lg: ($font-size-lg * $input-btn-line-height-lg) +
  ($input-btn-padding-y-lg * 2);
$input-height-lg: calc(#{$input-height-inner-lg} + #{$input-height-border});

// Dropdowns
$dropdown-link-color: $gray-700;
$dropdown-link-hover-bg: $gray-100;
$dropdown-item-padding-y: 0.35rem;
$dropdown-item-padding-x: 1.5rem;

// Cards
$card-border-radius: 0.25rem;
$card-inner-border-radius: $card-border-radius;
$card-border-width: 0;
$card-border-color: transparent;
$card-cap-bg: $white;
$card-shadow: 0 0 0.875rem 0 rgba($dark, 0.05);
$card-title-font-weight: $font-weight-bold;
$card-title-color: $gray-700;
$card-spacer-y: $spacer * 1.25;
$card-spacer-x: $spacer * 1.25;
$card-cap-padding-y: $spacer;
$card-cap-padding-x: $card-spacer-x;

// List group
$list-group-item-padding-y: 0.75rem;
$list-group-item-padding-x: 1.25rem;

// Tables
$table-cell-padding-y: 0.75rem;
$table-cell-padding-x: 0.75rem;
$table-cell-padding-y-sm: 0.3rem;
$table-cell-padding-x-sm: 0.3rem;
// $table-striped-bg: $gray-100;
$table-striped-bg: $color-lavado-gris;

// Sidebar general
$sidebar-width: 260px;
$sidebar-transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out,
  margin-right 0.35s ease-in-out, right 0.35s ease-in-out;
$sidebar-bg: (
  'default': #1d242b,
  'colored': #2d6bcf,
  'dark': #293042,
  'light': $static-white
);

// Sidebar brand
$sidebar-brand-padding-y: 1.15rem;
$sidebar-brand-padding-x: 1.5rem;
$sidebar-brand-font-weight: $font-weight-bold;
$sidebar-brand-font-size: 1.125rem;
$sidebar-brand-icon-color: (
  'default': lighten($primary, 2.5%),
  'colored': $static-white,
  'dark': lighten($primary, 2.5%),
  'light': darken($primary, 5%)
);
$sidebar-brand-icon-top-color: (
  'default': lighten($primary, 12.5%),
  'colored': rgba($static-white, 0.66),
  'dark': lighten($primary, 12.5%),
  'light': lighten($primary, 15%)
);
$sidebar-brand-color: (
  'default': $static-white,
  'colored': $static-white,
  'dark': $static-white,
  'light': lighten($static-gray-800, 25%)
);

// Sidebar header
$sidebar-header-padding: 1.5rem 1.5rem 0.375rem;
$sidebar-header-font-size: $font-size-sm;
$sidebar-header-color: (
  'default': $static-gray-400,
  'colored': $static-gray-400,
  'dark': $static-gray-400,
  'light': $static-gray-700
);

// Sidebar link
$sidebar-link-padding: 0.625rem 1.625rem;
$sidebar-link-font-weight: 400;
$sidebar-link-color: (
  'default': rgba(#fff, 1),
  'colored': rgba($static-gray-100, 0.7),
  'dark': rgba($static-gray-200, 0.5),
  'light': $static-gray-700
);

$sidebar-link-hover-font-weight: $font-weight-normal;
$sidebar-link-hover-color: (
  // "default": #{rgba($static-gray-500, 0.9)},
  'default': #{rgba(#fff, 0.8)},
  'colored': #{rgba($static-gray-100, 0.9)},
  'dark': #{rgba($static-gray-200, 0.75)},
  'light': #{lighten($primary, 5)}
);

$sidebar-link-active-font-weight: $font-weight-normal;
$sidebar-link-active-color: (
  'default': $static-gray-200,
  'colored': $static-gray-100,
  'dark': $static-gray-200,
  'light': #{lighten($primary, 5)}
);

// Sidebar child link
$sidebar-link-child-padding: 0.55rem 1.5rem 0.55rem 3.7rem;
$sidebar-link-child-font-size: 90%;
$sidebar-link-child-font-weight: 400;

$sidebar-link-child-color: (
  'default': $static-gray-500,
  'colored': $static-gray-400,
  'dark': $static-gray-500,
  'light': $static-gray-600
);
$sidebar-link-child-hover-color: (
  'default': $static-gray-200,
  'colored': $static-gray-100,
  'dark': $static-gray-200,
  'light': #{lighten($primary, 5)}
);
$sidebar-link-child-active-color: (
  'default': #{lighten($primary, 5)},
  'colored': $white,
  'dark': #{lighten($primary, 5)},
  'light': #{lighten($primary, 5)}
);

// Sidebar child link nested
$sidebar-link-child-second-padding: 0.55rem 1.5rem 0.55rem 4.5rem;
$sidebar-link-child-third-padding: 0.55rem 1.5rem 0.55rem 5.75rem;

// Sidebar link icon
$sidebar-link-icon-color: (
  'default': #{rgba($static-gray-200, 0.5)},
  'colored': #{rgba($static-gray-100, 0.5)},
  'dark': #{rgba($static-gray-200, 0.5)},
  'light': $static-gray-700
);
$sidebar-link-icon-hover-color: (
  'default': #{rgba($static-gray-200, 0.75)},
  'colored': #{rgba($static-gray-100, 0.75)},
  'dark': #{rgba($static-gray-200, 0.75)},
  'light': #{lighten($primary, 5)}
);
$sidebar-link-icon-active-color: (
  'default': $static-gray-200,
  'colored': $static-gray-100,
  'dark': $static-gray-200,
  'light': #{lighten($primary, 5)}
);

// Sidebar badges
$sidebar-badge-primary-color: (
  'default': $static-white,
  'colored': $dark,
  'dark': $static-white,
  'light': $static-white
);
$sidebar-badge-primary-bg: (
  'default': $primary,
  'colored': $static-white,
  'dark': $primary,
  'light': $primary
);

$sidebar-badge-secondary-color: (
  'default': $static-white,
  'colored': $static-dark,
  'dark': $static-white,
  'light': $static-white
);
$sidebar-badge-secondary-bg: (
  'default': $static-gray-600,
  'colored': rgba($static-white, 0.75),
  'dark': $static-gray-600,
  'light': $static-gray-600
);

// Sidebar compact
$sidebar-compact-width: 68px;

// Sidebar compact dropdown
$sidebar-compact-dropdown-padding: ($spacer / 2) 0;
$sidebar-compact-dropdown-width: 220px;
$sidebar-compact-dropdown-box-shadow: 0 0.5rem 3rem 0.5rem rgba($black, 0.05);
$sidebar-compact-dropdown-border-radius: $border-radius-lg;
$sidebar-compact-dropdown-background: $white;

// Sidebar compact link
$sidebar-compact-dropdown-link-padding: 0.5rem 1.5rem;
$sidebar-compact-dropdown-link-color: $gray-600;
$sidebar-compact-dropdown-link-hover-color: lighten($primary, 5);
$sidebar-compact-dropdown-link-active-color: lighten($primary, 5);

// Sidebar compact link children
$sidebar-compact-dropdown-child-link-padding: 0.5rem 1.5rem 0.5rem 2.5rem;
$sidebar-compact-dropdown-child-second-link-padding: 0.5rem 1.5rem 0.5rem 3.5rem;

// Sidebar bottom
$sidebar-cta-color: (
  'default': $static-gray-200,
  'colored': $static-gray-200,
  'dark': $static-gray-200,
  'light': $static-gray-800
);

$sidebar-cta-bg: (
  'default': #{lighten(#293042, 5%)},
  'colored': #{lighten(#2d6bcf, 3%)},
  'dark': #{lighten(#293042, 5%)},
  'light': #{darken($static-white, 3%)}
);

$sidebar-cta-padding: $spacer * 1.5;
$sidebar-cta-margin: $spacer * 1.75;
$sidebar-cta-border-radius: $border-radius-lg;

// Transitions
$transition-appearance: background 0.4s ease-in-out, color 0.4s ease-in-out;
$transition-appearance-slow: background 0.6s ease-in-out, color 0.6s ease-in-out;
$transition-appearance-fast: background 0.1s ease-in-out, color 0.1s ease-in-out;

// Modals
$modal-content-border-width: 0;
$modal-header-border-width: 1px;
$modal-footer-border-width: 1px;
$modal-xl: 1140px;
$modal-lg: 900px;
$modal-md: 600px;
$modal-sm: 400px;
$modal-transition: transform 0.25s ease-out;

// Tabs
$tab-content-padding: $spacer * 1.25;
$tab-content-box-shadow: 0 0.2rem 0.1rem -0.1rem rgba(0, 0, 0, 0.15);
$tab-content-border-radius: 0 0 $border-radius $border-radius;
$tab-link-color: $gray-800;
$tab-link-padding: ($spacer * 0.75) $spacer;
$tab-link-bg: transparent;
$tab-link-active-color: $gray-800;
$tab-link-active-bg: $white;

// Badges
$badge-font-size: 80%;
$badge-padding-y: 0.3em;
$badge-padding-x: 0.45em;
$badge-pill-padding-x: 0.65em;

// Pagination
$pagination-padding-y: 0.3rem;
$pagination-padding-x: 0.75rem;
$pagination-padding-y-sm: 0.15rem;
$pagination-padding-x-sm: 0.5rem;
$pagination-padding-y-lg: 0.35rem;
$pagination-padding-x-lg: 1rem;
$pagination-color: $gray-600;
$pagination-hover-color: $gray-800;

// Wrapper
$wrapper-border-color: $gray-300;

// Content
$content-padding: 2.5rem 2.5rem 1.5rem;
// Footer
$footer-background: $white;
$footer-border-top-color: $gray-300;

// Datetimepicker
$bs-datetimepicker-active-bg: $primary;

// Simplebar
$simplebar-scrollbar-bg: $white;

// Hamburger
$hamburger-width-top: 20px;
$hamburger-width-middle: 24px;
$hamburger-width-bottom: 16px;

// Stats
$stat-bg: lighten($primary, 35%);
$stat-icon-color: $primary;
$stat-padding: $spacer * 0.75;

// Illustration
$illustration-bg: lighten($primary, 35%);
$illustration-color: $primary;

// Font Awesome
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
