@import './assets/fonts/Poppins/stylesheet.css';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';

@import 'util-resolution.scss';
@import 'style/colors.scss';
@import 'style/template-fixes.scss';
@import 'style/template/light.scss'; //we import the template with light style

// /* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

//import swiperjs css
@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';

.custom-tooltip {
  background-color: $color-secundario-azul-marino;
  color: white;
  padding: 12px;
  border-radius: 8px;
  &:before {
    box-shadow: rgb(216, 212, 212) 0.0625rem -0.0625rem 0px 0px;
    content: '';
    display: block;
    height: 0.75rem;
    left: 50%;
    margin-top: -0.4rem;
    position: absolute;
    top: 100%;
    transform: translateX(-50%) rotate(135deg);
    width: 0.75rem;
    z-index: 1;
    background: $color-secundario-azul-marino;
    border-width: 0.125rem;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
  }
}

// Elements html
html,
body {
  height: 100%;
  font-family: 'poppinsregular', Roboto, 'Helvetica Neue', sans-serif !important;
  color: $color-texto-iconos;
}

body {
  overflow-y: unset;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'poppinsregular', Roboto, 'Helvetica Neue', sans-serif;
  background-color: white;
  color: $color-texto-iconos;
}

a {
  text-decoration: none;
  color: $color-texto-iconos;
  font-weight: 600;

  &:hover {
    color: $color-primario-verde;
    text-decoration: none;
  }
}

button {
  background-color: transparent;
  outline: none !important;
  border: none;
  color: $color-texto-iconos;
}
.btn-secondary--active {
  background-color: $color-primario-blanco;
  color: $color-primario-negro;
  border: 2px solid $color-primario-negro;
  width: 160px;
  height: 40px;
  border-radius: 8px;
  font-size: 14px;

  &:hover {
    cursor: pointer;
    background-color: $color-primario-negro;
    color: $color-primario-blanco;
  }
  @include inMobileResolution {
    width: 100%;
  }
}

.btn-secondary--inactive {
  background-color: $color-primario-blanco;
  color: $color-lavado-marron-oscuro;
  border: 2px solid $color-lavado-marron-oscuro;
  pointer-events: none;
  opacity: 0.65;
  width: 160px;
  height: 40px;
  border-radius: 8px;
  font-size: 14px;
  @include inMobileResolution {
    width: 100%;
  }
}
.btn-cancel--active {
  background-color: $color-cancelar;
  color: $color-primario-blanco;
  width: 160px;
  height: 40px;
  border-radius: 8px;
  font-size: 14px;

  &:hover {
    background-color: $color-cancelar-hover;
    cursor: pointer;
    color: $color-primario-blanco;
  }
  @include inMobileResolution {
    width: 100%;
  }
}
.btn-cancel--inactive {
  background-color: $color-lavado-marron-oscuro;
  color: $color-primario-blanco;
  pointer-events: none;
  opacity: 0.65;
  width: 160px;
  height: 40px;
  border-radius: 8px;
  font-size: 14px;
  @include inMobileResolution {
    width: 100%;
  }
}

.btn-danger--active {
  background-color: $color-cancelar;
  color: $color-primario-blanco;
  width: 160px;
  height: 40px;
  border-radius: 8px;
  font-size: 14px;

  &:hover {
    cursor: pointer;
    background-color: $color-cancelar-hover;
    color: $color-primario-blanco;
  }
  @include inMobileResolution {
    width: 100%;
  }
}
h1,
.mat-typography h1 {
  font-size: 20px;
  line-height: 39px;
  font-weight: 500;
}

h2,
.mat-typography h2 {
  font-size: 18px;
  line-height: 34px;
  font-weight: 500;
}

h3,
.mat-typography h3 {
  font-size: 16px;
  line-height: 27px;
  font-weight: 500;
}

h4,
.mat-typography h4 {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}

// They are the figures in the analytics tables and in the merchant detail
h5,
.mat-typography h5 {
  font-size: 40px;
  line-height: 60px;
  font-weight: 600;
}

input {
  height: 40px;
  line-height: 40px !important;
  border-radius: 4px;
  border: 2px solid $color-lavado-gris-oscuro !important;
  outline: none !important;
  padding: 4px 4px 4px 16px !important;
}

input[type='file'] {
  border: none !important;
  display: none;
}
.error {
  color: red;
}

textarea {
  resize: none;
  border-radius: 4px;
  border: 2px solid #d1dcdf !important;
}

select {
  border-radius: 4px;
  border: 2px solid #d1dcdf;
  outline: none;
  padding: 4px 4px 4px 16px;
  min-width: 130px;
  height: 40px;

  &:hover {
    cursor: pointer;
  }
}

th {
  text-transform: uppercase;
  font-weight: 600 !important;
}

td {
  border: none !important;
}

// Estilos popups
.mat-dialog-container {
  .mat-dialog-title {
    font-weight: bold;
  }
  .mat-dialog-content {
    display: block;
    overflow-y: hidden !important;
    margin: 0 !important;
    .container {
      padding: 0;
    }
  }
  .mat-dialog-actions {
    margin-bottom: 0 !important;
    min-height: auto !important;
  }
}

// input label (placeholder)
.mat-form-field-appearance-legacy .mat-form-field-label {
  padding: 8px 4px 2px 10px;
}

// Input underline (border-bottom)
.mat-form-field-appearance-legacy .mat-form-field-underline {
  // height: 1px;
  display: none;
}

// When the input is not filled and has been clicked (invalid)
.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  // caret-color: #f44336;
  caret-color: $color-secundario-naranja;
}

.mat-form-field-infix {
  border-top: none;
}

.mat-form-field-label-wrapper {
  display: none !important;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding-top: 0 !important;
}

// Butto Classes
.hr {
  background-color: $color-lavado-gris-oscuro;
  margin-bottom: 10px;
  height: 2px;
}

.hr-light {
  background-color: $color-lavado-gris-oscuro;
  margin-bottom: 10px;
  height: 1px;
}

.font-bold {
  font-weight: bold !important;
}
.font-medium {
  font-size: medium;
}
.default-font-color {
  color: $color-texto-iconos !important;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.pl-0 {
  padding-left: 0 !important;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.ml-2 {
  margin-left: 0.5rem !important;
}
.mr-4 {
  margin-right: 1.5rem !important;
}
.ml-4 {
  margin-left: 1.5rem !important;
}

.gap-10 > * {
  margin: 0 10px;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: $color-secundario-verde-oscuro;
}

// google maps info windows style
.gm-style .gm-style-iw-c {
  min-width: 345px !important;
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
}

@media only screen and (max-width: 480px) {
  // google maps info windows style
  .gm-style .gm-style-iw-c {
    min-width: 225px !important;
  }
  app-dialog-home-map app-custom-map {
    .gm-style .gm-style-iw-a {
      display: none !important;
      &::after {
        display: none !important;
      }
    }
  }
  app-dialog-home-map {
    // google maps info windows style
    .gm-style .gm-style-iw-c {
      min-width: 254px !important;
    }
    .ng-star-inserted {
      a {
        color: #233955 !important;
      }
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.p-paginator {
  margin-top: 1em;
  .p-paginator-current {
    margin-right: 1em;
  }

  .p-paginator-element {
    box-sizing: border-box;
    border: 1px solid $color-lavado-gris;
    padding: 5px;
    width: 2em;

    &.p-disabled {
      color: $color-lavado-gris-oscuro;
      cursor: default;
    }

    &:hover:not(.p-disabled),
    &.p-highlight {
      background-color: $color-basic-client;
      color: white;
    }
  }
}

// slider km in locations
app-card-location-service {
  .mat-slider-thumb {
    transform: scale(0) !important;
  }
  .mat-slider-horizontal {
    .mat-slider-thumb-label {
      top: -55px !important;
      right: -22px !important;
      transform: rotate(45deg) !important;
      border-radius: 50% 50% 0 !important;
      background-color: $color-primario-verde !important;
      background-color: $color-primario-verde !important;
      text-align: center !important;
      width: 48px !important;
      height: 48px !important;
      .mat-slider-thumb-label-text {
        opacity: 1 !important;
      }
    }
    .mat-slider-ticks-container {
      background-color: $color-primario-verde !important;
      height: 4px !important;
    }
  }
}
app-card-location-worker {
  .mat-slider-thumb {
    transform: scale(0) !important;
  }
  .mat-slider-horizontal {
    .mat-slider-thumb-label {
      top: -55px !important;
      right: -22px !important;
      transform: rotate(45deg) !important;
      border-radius: 50% 50% 0 !important;
      background-color: $color-primario-verde !important;
      background-color: $color-primario-verde !important;
      text-align: center !important;
      width: 48px !important;
      height: 48px !important;
      .mat-slider-thumb-label-text {
        opacity: 1 !important;
      }
    }
    .mat-slider-ticks-container {
      background-color: $color-primario-verde !important;
      height: 4px !important;
    }
  }
}
