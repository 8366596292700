// Colores primarios
$color-basic-client: #233955;
$color-primario-verde: #006368;
$color-primario-blanco: #fff;
$color-primario-negro: #000000;
$color-primario-azul: #008ca1;

// Colores secundarios
$color-secundario-naranja: #ff6051;
$color-secundario-amarillo: #ff9900;
$color-secundario-verde-oscuro: #003546;
$color-secundario-verde: #00a774;
$color-secundario-azul-marino: #232f34;

// Colores lavados
$color-lavado-gris: #f0f6f7;
$color-lavado-gris-oscuro: #d1dcdf;

// Color de texto e iconos
$color-texto-iconos: #495057;
$color-texto-secundario: #adadad;
$color-btn-disabled: #dcdcdc;

// Color cancelar
$color-cancelar: #e41e1e;
$color-cancelar-hover: #820b0b;

// cliente a (santander)
$color-client-a: #f01f24;
$premium-offers-cliente-a: #d8e9ef;
$font-color-cliente-a: #ffffff;
$font-color-cliente-a-premium: #233955;
$font-color-offer-details: #333a42;
$color-default-btn-a: #333a42;

// client b (unicaja_liberbank)
$color-client-b: #10791a;
$color-white-cliente-b: #ffffff;
$color-footer-client-b: #363638;
$color-background-client-b: #e5e1da;
$border-colors-client-b: #d8e9ef;
$color-default-btn-b: #363638;

// client aib (aib_bank)
$color-primary-aib: #d11a75;
$color-secundary-client-aib: #7e276d;
$border-colors-client-aib: #007960;
$color-white-cliente-aib: #ffffff;
$color-background-client-aib: #e5e1da;
$color-defaultt-btn-aib: #363638;

// client boots (boots)
$color-primary-boots: #f3f3f3;
$color-secundary-client-boots: #131b54;
$color-white-cliente-boots: #ffffff;
$color-defaultt-btn-boots: #004282;

// client bankIreland (bankIreland)
$color-primary-bankIreland: #00fdc1;
$color-secundary-client-bankIreland: #838383;
$color-white-cliente-bankIreland: #ffffff;
$color-defaultt-btn-bankIreland: #0000ff;

// client aviva (aviva)
$color-primary-aviva: #ffd230;
$color-secundary-client-aviva: #0059b1;
$color-white-cliente-aviva: #ffffff;
$color-defaultt-btn-aviva: #2d2d2d;

$color-grey: #cbc9d5;
$color-lavado-marron-oscuro: #bbb4b6;
$color-secundario-marron-raro: #6f6974;
$color-primario-gris: #495057;
$color-secundario-marron-raro: #6f6974;
$color-primario-azul: #008ca1;
$color-secundario-verde: #00a774;
// Color cancelar
$color-cancelar: #e41e1e;
$color-cancelar-hover: #820b0b;
