$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

@mixin inMobileResolution($maxWidth: $sm) {
    @include utilResolution(null, $maxWidth) {
        @content
    }
}

@mixin inSmallResolution($minWidth: $sm, $maxWidth: $md) {
    @include utilResolution($minWidth, $maxWidth) {
        @content
    }
}

@mixin inMediumResolution($minWidth: $md, $maxWidth: $lg) {
    @include utilResolution($minWidth, $maxWidth) {
        @content
    }
}

@mixin inLargeResolution($minWidth: $lg, $maxWidth: $xl) {
    @include utilResolution($minWidth, $maxWidth) {
        @content
    }
}

@mixin inExtraLargeResolution($minWidth: $xl) {
    @include utilResolution($minWidth, null) {
        @content
    }
}

@mixin utilResolution($minWidth, $maxWidth) {
    @if $minWidth != null and $maxWidth != null {
        @media (min-width: $minWidth) and (max-width: $maxWidth - 0.02px) {
            @content
        }
    }
    @else if $minWidth != null {
        @media (min-width: $minWidth) {
            @content
        }
    }
    @else if $maxWidth != null {
        @media (max-width: $maxWidth - 0.02px) {
            @content
        }
    }    
}